import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  isOpenModalDetailEvent: boolean;
  openModalDetailEvent: (
    start: Date | null,
    end: Date | null,
    title: string,
    desc: string
  ) => void;
  closeModalDetailEvent: () => void;
  startDetailEvent: Date | null;
  endDetailEvent: Date | null;
  title: string;
  desc: string | null | undefined;
}

interface IDetailEventModalContext {
  children: ReactNode;
}

const DetailEventModalContext = createContext<State | undefined>(undefined);

const DetailEventModalProvider = ({ children }: IDetailEventModalContext) => {
  const [isOpenModalDetailEvent, setIsOpenModalDetailEvent] = useState(false);
  const [startDetailEvent, setStartDetailEvent] = useState<Date | null>(null);
  const [endDetailEvent, setEndDetailEvent] = useState<Date | null>(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState<string | null | undefined>(null);

  const openModalDetailEvent = useCallback(
    (
      start: Date | null,
      end: Date | null,
      title: string,
      desc?: string | null
    ) => {
      setStartDetailEvent(start);
      setEndDetailEvent(end);
      setTitle(title);
      setDesc(desc);
      setIsOpenModalDetailEvent(true);
    },
    []
  );

  const closeModalDetailEvent = useCallback(() => {
    setIsOpenModalDetailEvent(false);
  }, []);

  const value = useMemo(
    () => ({
      isOpenModalDetailEvent,
      openModalDetailEvent,
      closeModalDetailEvent,
      startDetailEvent,
      endDetailEvent,
      title,
      desc,
    }),
    [
      closeModalDetailEvent,
      desc,
      endDetailEvent,
      isOpenModalDetailEvent,
      openModalDetailEvent,
      startDetailEvent,
      title,
    ]
  );

  return (
    <DetailEventModalContext.Provider value={value}>
      {children}
    </DetailEventModalContext.Provider>
  );
};

const useDetailEventModal = () => {
  const context = React.useContext(DetailEventModalContext);
  if (context === undefined) {
    throw new Error(
      "useDetailEventModal must be used within a DetailEventModalProvider"
    );
  }
  return context;
};

export {
  DetailEventModalContext,
  DetailEventModalProvider,
  useDetailEventModal,
};
