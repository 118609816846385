import React, { useState, useEffect } from "react";
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
// import { INITIAL_EVENTS } from "../../utils/event-utils";
import listPlugin from "@fullcalendar/list";
import idLocale from "@fullcalendar/core/locales/id";
import "./Kalender.css";
import { Box, useTheme, useMediaQuery } from "@mui/material";
// import { usePesanModal } from "../../context/pesanModalContext";
import { usePesanModal } from "../../context/pesanModalContext";
import { defaultColor } from "../../constants/common";
import { useDetailEventModal } from "../../context/detailEventModalContext";
import { ParamsGetBooking } from "../../constants/types";
import useBookings from "../../services/queries/useBookings";
import Spinner from "../Spinner/Spinner";

interface IKalenderProps {
  lapanganId: number;
}

const Kalender = ({ lapanganId }: IKalenderProps) => {
  var moment = require("moment");
  const { openModalDetailEvent } = useDetailEventModal();
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openModalPesan } = usePesanModal();
  const initialParamsBooking = {
    start: "",
    end: "",
    lapanganId: lapanganId,
  };

  const [paramsBooking, setBooking] =
    useState<ParamsGetBooking>(initialParamsBooking);

  const { data: bookings, status: statusBooking } = useBookings(paramsBooking);

  const handleModal = (selectInfo: DateSelectArg) => {
    const tanggal = new Date(selectInfo.startStr);
    const waktuAwal = new Date(selectInfo.startStr);
    // const waktuAkhir = moment(selectInfo.start).add(1, "h");
    const waktuAkhir = new Date(selectInfo.endStr);
    openModalPesan(tanggal, waktuAwal, waktuAkhir);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    // console.log("clickInfo:", clickInfo);
    const start = new Date(clickInfo.event.startStr);
    const end = new Date(clickInfo.event.endStr);
    const title = clickInfo.event.title;
    const desc = clickInfo.event.extendedProps.desc;
    openModalDetailEvent(start, end, title, desc);
    // alert(
    //   `Are you sure you want to delete the event '${clickInfo.event.title}'`
    // );
  };

  // const handleEvents = (events: EventApi[]) => {
  //   setCurrentEvents(events);
  // };

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        <b>
          {eventContent.event.title === ""
            ? "Booking Baru"
            : eventContent.event.title}
        </b>
        <br />
        <b>{eventContent.timeText}</b>
      </>
    );
  };

  // const selectRange = (nowDate: Date) => {
  //   return {
  //     start: nowDate,
  //   };
  // };

  useEffect(() => {
    setBooking((prev) => ({
      ...prev,
      lapanganId: lapanganId,
    }));
  }, [lapanganId]);

  if (statusBooking === "loading") {
    return (
      <Box position="relative" height={700}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box className="demo-app-main" marginY={3}>
      <FullCalendar
        height={700}
        locale={idLocale}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          momentPlugin,
        ]}
        headerToolbar={{
          left: "today,prev,next",
          center: "title",
          right: isPhoneScreen
            ? "timeGridThreeDays,listThreeDays"
            : "timeGridWeek,listWeek",
        }}
        initialView={isPhoneScreen ? "timeGridThreeDays" : "timeGridWeek"}
        views={{
          timeGridThreeDays: {
            type: "timeGrid",
            duration: { days: 3 },
            buttonText: "Booking",
          },
          listThreeDays: {
            type: "list",
            duration: { days: 3 },
            buttonText: "Jadwal",
          },
        }}
        // contentHeight="auto"
        buttonText={{
          week: "Booking",
          list: "Jadwal",
        }}
        // dayMinWidth={100}
        // stickyFooterScrollbar={true}
        //         dayMinWidth: 150,
        // stickyFooterScrollbar : true,
        windowResizeDelay={800}
        allDaySlot={false}
        expandRows={true}
        nowIndicator={true}
        editable={false}
        selectable={true}
        selectMirror={true}
        selectAllow={function (selectInfo) {
          return moment().diff(selectInfo.start) <= 0;
        }}
        selectOverlap={false}
        // selectLongPressDelay={1} //untuk mensimulasikan touch
        dayMaxEvents={true}
        weekends={true}
        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        events={bookings}
        select={handleModal}
        eventColor={defaultColor}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventTimeFormat="HH:mm"
        //   eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        slotDuration="01:00:00"
        slotLabelFormat="HH:mm"
        slotMinTime="06:00:00"
        // validRange={function (nowDate) {
        //   var endDate = new Date(nowDate.valueOf());
        //   endDate.setDate(endDate.getDate() + 30);
        //   return {
        //     end: endDate,
        //   };
        // }}
        // validRange={selectRange}
        /* you can update a remote database when these fire:
                  eventAdd={function(){}}
                  eventChange={function(){}}
                  eventRemove={function(){}}
                  */
      />
    </Box>
  );
};

export default Kalender;
