import {
  Box,
  Stack,
  Typography,
  Button,
  // Switch,
  useTheme,
  useMediaQuery,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import Kalender from "../../components/Kalender/Kalender";
import { defaultColor } from "../../constants/common";
import { usePesanModal } from "../../context/pesanModalContext";
import { ReactComponent as TutupSvg } from "../../assets/svg/Tutup.svg";
import useSettings from "../../services/queries/useSettings";
import ModalDetailEvent from "../../components/ModalDetailEvent/ModalDetailEvent";
import ModalPesan from "../../components/ModalPesan/ModalPesan";
import useLapangan from "../../services/queries/useLapangan";
import { ParamsLapangan } from "../../constants/types";
import Spinner from "../../components/Spinner/Spinner";

interface IMainPageProps {}

const MainPage = (props: IMainPageProps) => {
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { openModalPesan } = usePesanModal();
  const initialParamsLapangan = {
    lapangan_id: "",
  };

  const [paramsLapangan, setLapangan] = useState<ParamsLapangan>(
    initialParamsLapangan
  );
  const { data: lapangan, status: statusLapangan } =
    useLapangan(paramsLapangan);
  const { data: settings, status: statusSettings } = useSettings();
  const [lapanganId, setLapanganId] = useState<number>(1);
  // const [isTutup, setIsTutup] = useState(false);

  const isLoading =
    statusSettings === "loading" || statusLapangan === "loading";

  if (isLoading) {
    return (
      <Box position="relative" height="calc(100vh - 92.22px)">
        <Spinner />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        justifyContent="space-between"
        alignItems="center"
        rowGap={3}
      >
        <Stack spacing={0} direction="column">
          <Typography variant={isPhoneScreen ? "h5" : "h4"} fontWeight="bold">
            Jadwal Lapangan
          </Typography>
          <Typography
            variant={isPhoneScreen ? "body1" : "h6"}
            fontSize={isPhoneScreen ? "14px" : undefined}
            color="#696A79"
          >
            Pesan langsung secara online. Mudah &amp; Cepat!
          </Typography>
        </Stack>
        <Button
          disabled={!settings?.aktif}
          variant="contained"
          onClick={() => openModalPesan(null, null)}
          // size="large"
          sx={{
            display: "none",
            gridRowStart: isPhoneScreen ? 2 : undefined,
            gridColumn: isPhoneScreen ? "1 / 3" : undefined,
            width: isPhoneScreen ? "100%" : "189px",
            justifySelf: "end",
            height: "57px",
            fontSize: "18px",
            padding: 0,
            textTransform: "none",
          }}
        >
          Pesan Lapangan
        </Button>
      </Box>
      {!settings?.aktif ? (
        <Box
          display="flex"
          justifyContent="center"
          marginTop={isPhoneScreen ? 4 : 8}
        >
          <TutupSvg
            style={{
              width: isPhoneScreen ? "100%" : isTabletScreen ? "80%" : "70%",
              height: "fit-content",
            }}
          />
        </Box>
      ) : (
        <React.Fragment>
          {/* <Box
            display="grid"
            gridTemplateColumns={
              isPhoneScreen || isTabletScreen ? "1fr auto" : "1fr 1fr"
            }
            justifyContent="space-between"
            // alignItems="center"
            rowGap="10px"
            marginTop={isPhoneScreen ? 4 : 8}
            marginBottom={4}
          > */}
          <Box marginTop={isPhoneScreen ? 4 : 8} marginBottom={4}>
            {isPhoneScreen ? (
              <Stack spacing={0} direction="column">
                <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                  Pilih lapangan
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={String(lapanganId)}
                    onChange={(event: SelectChangeEvent) => {
                      setLapanganId(Number(event.target.value));
                    }}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    {lapangan &&
                      lapangan.length > 0 &&
                      lapangan.map((li) => (
                        <MenuItem key={li.id} value={li.id}>
                          {li.nama}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>
            ) : (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                gap={2}
              >
                {lapangan &&
                  lapangan.length > 0 &&
                  lapangan.map((li) => {
                    return (
                      <React.Fragment key={li.id}>
                        <Button
                          variant="contained"
                          onClick={() => setLapanganId(li.id)}
                          fullWidth
                          sx={{
                            // width: "128px",
                            // maxWidth: isPhoneScreen ? "100%" : "200px",
                            bgcolor:
                              lapanganId === li.id
                                ? defaultColor
                                : "rgba(9, 95, 52, 0.5)",
                            height: isPhoneScreen ? "34px" : "53px",
                            fontSize: isPhoneScreen ? "14px" : "18px",
                            padding: 0,
                            textTransform: "none",
                          }}
                        >
                          {li.nama}
                        </Button>
                      </React.Fragment>
                    );
                  })}
              </Box>
            )}
          </Box>

          {/* </Box> */}
          <svg width="100%" height="10">
            <rect width="100%" height="10" rx="6" ry="6" fill={defaultColor} />
          </svg>
          <Box display="flex" justifyContent="flex-end">
            <Typography
              variant="body1"
              // variant={isPhoneScreen ? "body1" : "h6"}
              fontSize="18px"
              textAlign={isPhoneScreen ? "center" : "right"}
              fontWeight="bold"
              color="#ff0000"
              sx={{
                width: isPhoneScreen ? "100%" : "230px",
                gridRowStart: isPhoneScreen ? 2 : undefined,
                marginTop: "24px",
              }}
            >
              {isPhoneScreen
                ? "Klik dan tahan jam yang diinginkan untuk booking instan"
                : "Klik jam yang diinginkan untuk booking instan"}
            </Typography>
          </Box>
          <Kalender lapanganId={lapanganId} />
        </React.Fragment>
      )}
      <ModalPesan lapanganId={lapanganId} />
      <ModalDetailEvent />
    </React.Fragment>
  );
};

export default MainPage;
