import { useQuery } from "react-query";
import { ParamsGetBooking, GetBookingResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetBooking) => {
  const apiGetBooking = queryString.stringifyUrl({
    url: "api/get-bookings",
    query: {
      start: params.start,
      end: params.end,
      lapanganId: params.lapanganId,
    },
  });
  const { data } = await axios.get<GetBookingResponse>(apiGetBooking);
  return data.data;
};

export default function useBookings(params: ParamsGetBooking) {
  return useQuery<GetBookingResponse["data"]>(["booking", params], () =>
    handleRequest(params)
  );
}
