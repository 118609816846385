import { useQuery } from "react-query";
import { ParamsLapangan, LapanganResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsLapangan) => {
  const apiLapangan = queryString.stringifyUrl({
    url: "api/get-lapangans",
    query: {
      lapangan_id: params.lapangan_id,
    },
  });
  const { data } = await axios.get<LapanganResponse>(apiLapangan);
  return data.data;
};

export default function useLapangan(params: ParamsLapangan) {
  return useQuery<LapanganResponse["data"]>(["Lapangan", params], () =>
    handleRequest(params)
  );
}
