import React from "react";
import "./App.css";
import {
    Box,
    Button,
    CssBaseline,
    AppBar,
    Toolbar,
    Link,
    Container,
    useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import { idID } from "@mui/material/locale";
import MainPage from "./pages/MainPage/MainPage";
import AppProvider from "./context";
import { defaultColor, font, wordpressUrl } from "./constants/common";
import LoginImage from "./assets/png/kickoff-logo.png";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 3,
        },
    },
});

function App() {
    const theme = createTheme(
        {
            shape: {
                borderRadius: 6,
            },
            palette: {
                primary: {
                    main: defaultColor,
                    // light: "#6AB893",
                    // dark: "#307454",
                    // contrastText: "#fff",
                },
                secondary: {
                    main: "#ffd600",
                },
                text: {
                    primary: "#2E2F49",
                },
                // background: {
                //   default: "#F5F5F4",
                // },
            },
            // typography: {
            //   fontFamily: font,
            // },
            // shadows: shadows.map(() => "none") as Shadows,
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            boxShadow: shadows.map(() => "none") as Shadows,
                        },
                    },
                },
            },
        },
        idID
    );

    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <ThemeProvider theme={theme}>
                    <Box sx={{ display: "flex" }}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            sx={{
                                // zIndex: (theme) => theme.zIndex.drawer + 1,
                                boxShadow: "none",
                            }}
                        >
                            <Toolbar>
                                <Container maxWidth="lg" sx={{ paddingY: "10px" }}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box width="104.63px" height="72.23px">
                                            <Link href={wordpressUrl}>
                                                <img
                                                    alt="Logo Kickoff Arena"
                                                    src={LoginImage}
                                                    width="100%"
                                                    height="100%"
                                                    style={{ objectFit: "contain" }}
                                                />
                                            </Link>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            // size="large"
                                            href={wordpressUrl}
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: 700,
                                                fill: "#13243A",
                                                color: "#13243A",
                                                backgroundColor: "#FFFFFF",
                                                paddingX: "40px",
                                                "&:hover": {
                                                    //you want this to be the same as the backgroundColor above
                                                    backgroundColor: "#FFF",
                                                },
                                            }}
                                        >
                                            {isPhoneScreen
                                                ? "Kembali"
                                                : "Kembali Ke Home"}
                                        </Button>
                                    </Box>
                                </Container>
                            </Toolbar>
                        </AppBar>
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                // paddingX: "95px",
                                paddingTop: "64px",
                            }}
                        >
                            <Toolbar
                                sx={{ display: isPhoneScreen ? "none" : undefined }}
                            />
                            <Container
                                maxWidth="lg"
                                sx={{ padding: 3, paddingTop: isPhoneScreen ? 9 : 3 }}
                            >
                                <MainPage />
                            </Container>
                        </Box>
                    </Box>
                </ThemeProvider>
            </AppProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    );
}

export default App;
