import React, { ReactNode } from "react";
import { DetailEventModalProvider } from "./detailEventModalContext";
import { PesanModalProvider } from "./pesanModalContext";

interface IindexProps {
  children: ReactNode;
}

const AppProvider = ({ children }: IindexProps) => {
  return (
    <PesanModalProvider>
      <DetailEventModalProvider>{children}</DetailEventModalProvider>
    </PesanModalProvider>
  );
};

export default AppProvider;
