import { useQuery } from "react-query";
import { ParamsGetHarga, GetHargaResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetHarga) => {
  const apiGetHarga = queryString.stringifyUrl({
    url: "api/get-harga",
    query: {
      start: params.start,
      end: params.end,
    },
  });
  const { data } = await axios.get<GetHargaResponse>(apiGetHarga);
  return data.data;
};

export default function useHarga(params: ParamsGetHarga) {
  return useQuery<GetHargaResponse["data"]>(["Harga", params], () =>
    handleRequest(params)
  );
}
