import {
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  Typography,
  DialogContent,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDetailEventModal } from "../../context/detailEventModalContext";

interface IModalDetailEventProps {}

const ModalDetailEvent = (props: IModalDetailEventProps) => {
  var moment = require("moment");
  const {
    isOpenModalDetailEvent,
    closeModalDetailEvent,
    startDetailEvent,
    endDetailEvent,
    title,
    desc,
  } = useDetailEventModal();
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const formattedTanggal = startDetailEvent?.toLocaleDateString("id", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedWaktuAwal = moment(startDetailEvent).format("HH:mm");

  const formattedWaktuAkhir = moment(endDetailEvent).format("HH:mm");

  // const formattedWaktuAwal = startDetailEvent?.toLocaleTimeString("id", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  // const formattedWaktuAkhir = endDetailEvent?.toLocaleTimeString("id", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  return (
    <Dialog
      maxWidth="md"
      fullScreen={isPhoneScreen}
      fullWidth
      open={isOpenModalDetailEvent}
      onClose={closeModalDetailEvent}
      PaperProps={{
        sx: {
          borderRadius: 1,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={closeModalDetailEvent}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight="bold" fontSize="24px">
            Detail Booking
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={5}>
          <Stack spacing={0} direction="column">
            <Typography variant="subtitle2" fontWeight="bold">
              Tanggal Booking
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight="regular"
              color="rgba(0, 0, 0, 0.6)"
            >
              {formattedTanggal}
            </Typography>
          </Stack>
          <Stack spacing={0} direction="column">
            <Typography variant="subtitle2" fontWeight="bold">
              Periode Waktu
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight="regular"
              color="rgba(0, 0, 0, 0.6)"
            >
              {`${formattedWaktuAwal} - ${formattedWaktuAkhir}`}
            </Typography>
          </Stack>
        </Stack>
        <Box marginTop={2} marginBottom={3}>
          <Divider />
        </Box>
        <Stack spacing={0} direction="column" marginBottom={3}>
          <Typography variant="body1" fontWeight="bold">
            Nama Tim
          </Typography>
          <Typography
            variant="body1"
            fontWeight="regular"
            color="rgba(0, 0, 0, 0.6)"
          >
            {title}
          </Typography>
        </Stack>
        {desc ? (
          <Stack spacing={0} direction="column" marginBottom={3}>
            <Typography variant="body1" fontWeight="bold">
              Deskripsi
            </Typography>
            <Typography
              variant="body1"
              fontWeight="regular"
              color="rgba(0, 0, 0, 0.6)"
            >
              {desc}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalDetailEvent;
