import {
  Dialog,
  DialogTitle,
  CircularProgress,
  Stack,
  IconButton,
  Typography,
  DialogContent,
  Box,
  Grid,
  TextField,
  Checkbox,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { usePesanModal } from "../../context/pesanModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import idLocale from "date-fns/locale/id";
import { defaultColor } from "../../constants/common";
import "./ModalPesan.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ParamsGetHarga,
  ParamsLapangan,
  PesanBody,
} from "../../constants/types";
import useHarga from "../../services/queries/useHarga";
import toRupiah from "../../utils/toRupiah";
import useLapangan from "../../services/queries/useLapangan";
import useSettings from "../../services/queries/useSettings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface IModalPesanProps {
  lapanganId: number;
}

const schema = yup.object({
  isWasit: yup.boolean(),
  isFotografer: yup.boolean(),
  tanggal: yup.date().required("Kolom wajib diisi"),
  waktuAwal: yup.date().required("Kolom wajib diisi"),
  waktuAkhir: yup.date().required("Kolom wajib diisi"),
  namaTim: yup.string().required("Kolom wajib diisi"),
  isSetuju: yup.boolean(),
  lapangan: yup.string().required("Kolom wajib diisi"),
});

const ModalPesan = ({ lapanganId }: IModalPesanProps) => {
  var moment = require("moment");
  const {
    isOpenModalPesan,
    closeModalPesan,
    tanggalPesan,
    waktuAwalPesan,
    waktuAkhirPesan,
  } = usePesanModal();
  const theme = useTheme();
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const initialParamsHarga = {
    start: "",
    end: "",
  };
  const [paramsHarga, setHarga] = useState<ParamsGetHarga>(initialParamsHarga);
  const { data: harga, status: statusHarga } = useHarga(paramsHarga);
  const initialParamsLapangan = {
    lapangan_id: 1,
  };
  const [paramsLapangan, setLapangan] = useState<ParamsLapangan>(
    initialParamsLapangan
  );
  const { data: lapangan } = useLapangan(paramsLapangan);
  const { data: settings } = useSettings();
  // const [isOpenModalPesan, setIsOpenModalPesan] = useState(false);
  // const [tanggal, setTanggal] = useState("");
  // const [waktu, setWaktu] = useState("");
  // const [tim, setTim] = useState("");
  // const [isFotografer, setIsFotografer] = useState(false);
  // const [isWasit, setIsWasit] = useState(false);
  // const [isSetuju, setIsSetuju] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false);

  const initialValues = useMemo(
    () => ({
      isWasit: false,
      isFotografer: false,
      tanggal: new Date(),
      waktuAwal: new Date(new Date().setMinutes(0)),
      waktuAkhir: new Date(
        new Date(new Date().setHours(new Date().getHours() + 1)).setMinutes(0)
      ),
      namaTim: "",
      isSetuju: false,
      lapangan: String(lapangan?.find((li) => li.id === lapanganId)?.nama),
    }),
    [lapangan, lapanganId]
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    // resetField,
    setValue,
    // getValues,
    // setError,
    // clearErrors,
    watch,
  } = useForm<PesanBody>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const start = moment(watch("waktuAwal")).format("YYYY-MM-DD HH:mm:ss");
  const end = moment(watch("waktuAkhir")).format("YYYY-MM-DD HH:mm:ss");

  const onSubmit = (values: PesanBody) => {
    const formattedTanggal = values.tanggal.toLocaleDateString("id", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const formattedWaktuAwal = values.waktuAwal.toLocaleTimeString("id", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const formattedWaktuAkhir = values.waktuAkhir.toLocaleTimeString("id", {
      hour: "2-digit",
      minute: "2-digit",
    });

    window.open(
      `https://wa.me/${
        settings?.nomor_wa
      }?text=Halo%20saya%20ingin%20pesan%0ALapangan:%20${
        values.lapangan
      }%0AAtas%20nama:%20${
        values.namaTim
      }%0ATanggal:%20${formattedTanggal}%0AJam:%20${formattedWaktuAwal}%20-%20${formattedWaktuAkhir}%0AWasit:%20${
        values.isWasit === true ? "Perlu" : "Tidak Perlu"
      }%0AFotografer:%20${
        values.isFotografer === true ? "Perlu" : "Tidak Perlu"
      }`,
      "_blank"
    );
  };

  const { today } = useMemo(
    () => ({
      today: new Date(),
    }),
    []
  );

  useEffect(() => {
    if (isOpenModalPesan) {
      if (tanggalPesan && waktuAwalPesan && waktuAkhirPesan) {
        reset({
          ...initialValues,
          tanggal: tanggalPesan,
          waktuAwal: waktuAwalPesan,
          waktuAkhir: waktuAkhirPesan,
          // lapangan: String(lapangan?.find((li) => li.id === lapanganId)?.nama),
        });
      } else {
        reset(initialValues);
      }
    }
  }, [
    reset,
    initialValues,
    isOpenModalPesan,
    tanggalPesan,
    waktuAwalPesan,
    waktuAkhirPesan,
    lapangan,
    lapanganId,
  ]);

  useEffect(() => {
    setHarga((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  }, [end, start]);

  useEffect(() => {
    console.log(lapangan?.find((li) => li.id === lapanganId));
  }, [lapangan, lapanganId]);

  useEffect(() => {
    console.log(lapanganId);
    setLapangan((prev) => ({
      ...prev,
      lapangan_id: lapanganId,
    }));
  }, [isOpenModalPesan, lapanganId]);

  useEffect(() => {
    console.log("start", start);
    console.log("end", end);
  }, [end, start]);

  return (
    <Dialog
      maxWidth="lg"
      fullScreen={isPhoneScreen}
      fullWidth
      open={isOpenModalPesan}
      onClose={closeModalPesan}
      PaperProps={{
        sx: {
          borderRadius: 1,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={closeModalPesan}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight="bold" fontSize="24px">
            Pesan Lapangan
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {/* <code>{JSON.stringify(getValues())}</code> */}
        <Stack spacing={6} direction="column">
          <Box display="flex" justifyContent="space-between">
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" fontWeight="bold" marginBottom="18px">
                  Waktu Booking
                </Typography>
                <Stack spacing={3} direction="column">
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                      Pilih tanggal booking
                    </Typography>
                    <Controller
                      name="tanggal"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={idLocale}
                        >
                          <DatePicker
                            inputFormat="dd-MM-yyyy"
                            disableMaskedInput
                            minDate={today}
                            open={isOpenDatePicker}
                            onOpen={() => setIsOpenDatePicker(true)}
                            onClose={() => setIsOpenDatePicker(false)}
                            toolbarTitle="Pilih Tanggal"
                            cancelText="Batal"
                            readOnly
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Tekan untuk memilih tanggal",
                                }}
                                // onClick={() => setIsOpenDatePicker(true)}
                                error={Boolean(errors.tanggal)}
                                helperText={
                                  errors.tanggal ? errors.tanggal.message : ""
                                }
                              />
                            )}
                            {...field}
                            onChange={(newValue: any) => {
                              setValue("waktuAwal", newValue.setMinutes(0));
                              field.onChange(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Stack>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                      Pilih jam booking
                    </Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Controller
                        name="waktuAwal"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={idLocale}
                          >
                            <TimePicker
                              minutesStep={60}
                              views={["hours"]}
                              // value={waktuAwalPesan}
                              // onChange={(newValue) => aturWaktuPesan(newValue)}
                              open={isOpenTimePicker}
                              onOpen={() => setIsOpenTimePicker(true)}
                              onClose={() => setIsOpenTimePicker(false)}
                              toolbarTitle="Pilih Waktu"
                              cancelText="Batal"
                              readOnly
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Tekan untuk memilih waktu",
                                  }}
                                  // onClick={() => setIsOpenTimePicker(true)}
                                  error={Boolean(errors.waktuAwal)}
                                  helperText={
                                    errors.waktuAwal
                                      ? errors.waktuAwal.message
                                      : ""
                                  }
                                />
                              )}
                              {...field}
                              onChange={(newValue: any) => {
                                const newTime = new Date(newValue);
                                setValue(
                                  "waktuAkhir",
                                  new Date(
                                    newTime.setHours(newTime.getHours() + 1)
                                  )
                                );
                                field.onChange(newValue);
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                        &mdash;
                      </Typography>
                      <Controller
                        name="waktuAkhir"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={idLocale}
                          >
                            <TimePicker
                              minutesStep={60}
                              views={["hours"]}
                              // value={waktuAwalPesan}
                              // onChange={(newValue) => aturWaktuPesan(newValue)}
                              open={isOpenTimePicker}
                              onOpen={() => setIsOpenTimePicker(true)}
                              onClose={() => setIsOpenTimePicker(false)}
                              toolbarTitle="Pilih Waktu"
                              cancelText="Batal"
                              readOnly
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Tekan untuk memilih waktu",
                                  }}
                                  // onClick={() => setIsOpenTimePicker(true)}
                                  error={Boolean(errors.waktuAwal)}
                                  helperText={
                                    errors.waktuAwal
                                      ? errors.waktuAwal.message
                                      : ""
                                  }
                                />
                              )}
                              {...field}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" fontWeight="bold" marginBottom="18px">
                  Keterangan
                </Typography>
                <Stack spacing={3} direction="column">
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                      Lapangan
                    </Typography>
                    <Controller
                      name="lapangan"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="lapangan"
                          // placeholder="Masukkan nama tim"
                          fullWidth
                          error={Boolean(errors.lapangan)}
                          helperText={
                            errors.lapangan ? errors.lapangan.message : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          {...field}
                        />
                      )}
                      rules={{ required: "Lapangan required" }}
                    />
                  </Stack>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                      Nama Tim
                    </Typography>
                    <Controller
                      name="namaTim"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="namaTim"
                          placeholder="Masukkan nama tim"
                          fullWidth
                          error={Boolean(errors.namaTim)}
                          helperText={
                            errors.namaTim ? errors.namaTim.message : ""
                          }
                          {...field}
                        />
                      )}
                      rules={{ required: "Nama Tim required" }}
                    />
                  </Stack>
                  <Stack spacing={1} direction="column">
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                      Opsional
                    </Typography>
                    <Stack spacing={2} direction="row">
                      <Controller
                        name="isFotografer"
                        control={control}
                        render={({ field }) => (
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Checkbox
                              disableRipple
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                padding: 0,
                                color: defaultColor,
                                "&.Mui-checked": {
                                  color: defaultColor,
                                },
                              }}
                              {...field}
                            />
                            <Typography variant="body2">Fotografer</Typography>
                          </Stack>
                        )}
                      />
                      <Controller
                        name="isWasit"
                        control={control}
                        render={({ field }) => (
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <Checkbox
                              disableRipple
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                padding: 0,
                                color: defaultColor,
                                "&.Mui-checked": {
                                  color: defaultColor,
                                },
                              }}
                              {...field}
                            />
                            <Typography variant="body2">Wasit</Typography>
                          </Stack>
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Stack spacing="18px" direction="column">
            <Typography variant="h6" fontWeight="bold">
              Biaya
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    fontWeight="regular"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    Harga Lapangan per jam
                  </Typography>
                  {statusHarga === "loading" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Typography variant="h6" fontWeight="bold">
                      {toRupiah(Number(harga?.harga))}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={3} direction="column">
            <Controller
              name="isSetuju"
              control={control}
              render={({ field }) => (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="flex-start"
                  alignSelf={
                    isPhoneScreen || isTabletScreen ? "center" : undefined
                  }
                >
                  <Checkbox
                    disableRipple
                    // checked={isSetuju}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    //   setIsSetuju(event.target.checked)
                    // }
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      padding: 0,
                      color: defaultColor,
                      "&.Mui-checked": {
                        color: defaultColor,
                      },
                    }}
                    {...field}
                  />
                  <Typography
                    variant="body2"
                    color="rgba(0, 0, 0, 0.6)"
                    sx={{
                      width:
                        isPhoneScreen || isTabletScreen ? "unset" : "450px",
                    }}
                  >
                    Saya menyetujui pesanan sesuai dengan jadwal yang dipilih.
                    Ketersediaan lapangan sewaktu-waktu dapat berubah dan dapat
                    dibicarakan ulang dengan admin Kickoff.
                  </Typography>
                </Stack>
              )}
            />
            <Button
              disabled={watch("isSetuju") === false}
              variant="contained"
              startIcon={<WhatsAppIcon />}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              sx={{
                alignSelf:
                  isPhoneScreen || isTabletScreen ? "flex-end" : undefined,
                width: "189px",
                height: "57px",
                fontSize: "21px",
                padding: 0,
                textTransform: "none",
                bgcolor: "#07B54C",
                "&:hover": {
                  bgcolor: "#06a144",
                },
              }}
            >
              Pesan
            </Button>
          </Stack>
        </Stack>

        {/* <LoadingButton
              loading={isLoadingButton}
              loadingPosition="center"
              variant="contained"
              fullWidth={isPhoneScreen}
              onClick={() => handlingPrintBarcode()}
              disabled={jumlahCetak > 150 || jumlahCetak <= 0}
            >
              Cetak
            </LoadingButton> */}
      </DialogContent>
    </Dialog>
  );
};

export default ModalPesan;
