import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  isOpenModalPesan: boolean;
  openModalPesan: (
    tanggal?: Date | null,
    waktuAwal?: Date | null,
    waktuAkhir?: Date | null
  ) => void;
  closeModalPesan: () => void;
  tanggalPesan?: Date | null;
  waktuAwalPesan?: Date | null;
  waktuAkhirPesan?: Date | null;
  aturTanggalPesan: (tanggal?: Date | null) => void;
  aturWaktuPesan: (waktuAwal?: Date | null) => void;
}

interface IPesanModalContext {
  children: ReactNode;
}

const PesanModalContext = createContext<State | undefined>(undefined);

const PesanModalProvider = ({ children }: IPesanModalContext) => {
  const [isOpenModalPesan, setIsOpenModalPesan] = useState(false);
  const [tanggalPesan, setTanggalPesan] = useState<Date | null | undefined>(
    null
  );
  const [waktuAwalPesan, setWaktuAwalPesan] = useState<Date | null | undefined>(
    null
  );
  const [waktuAkhirPesan, setWaktuAkhirPesan] = useState<
    Date | null | undefined
  >(null);

  const openModalPesan = useCallback(
    (
      tanggal?: Date | null,
      waktuAwal?: Date | null,
      waktuAkhir?: Date | null
    ) => {
      setTanggalPesan(tanggal);
      setWaktuAwalPesan(waktuAwal);
      setWaktuAkhirPesan(waktuAkhir);
      setIsOpenModalPesan(true);
    },
    []
  );

  const closeModalPesan = useCallback(() => {
    setIsOpenModalPesan(false);
  }, []);

  const aturTanggalPesan = useCallback((tanggal?: Date | null) => {
    setTanggalPesan(tanggal);
  }, []);

  const aturWaktuPesan = useCallback((waktuAwal?: Date | null) => {
    setWaktuAwalPesan(waktuAwal);
  }, []);

  const value = useMemo(
    () => ({
      isOpenModalPesan,
      openModalPesan,
      closeModalPesan,
      tanggalPesan,
      waktuAwalPesan,
      waktuAkhirPesan,
      aturTanggalPesan,
      aturWaktuPesan,
    }),
    [
      aturTanggalPesan,
      aturWaktuPesan,
      closeModalPesan,
      isOpenModalPesan,
      openModalPesan,
      tanggalPesan,
      waktuAkhirPesan,
      waktuAwalPesan,
    ]
  );

  return (
    <PesanModalContext.Provider value={value}>
      {children}
    </PesanModalContext.Provider>
  );
};

const usePesanModal = () => {
  const context = React.useContext(PesanModalContext);
  if (context === undefined) {
    throw new Error("usePesanModal must be used within a PesanModalProvider");
  }
  return context;
};

export { PesanModalContext, PesanModalProvider, usePesanModal };
