import { useQuery } from "react-query";
import { GetSettingResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async () => {
  const { data } = await axios.get<GetSettingResponse>("api/get-settings");
  return data.data;
};

export default function useSettings() {
  return useQuery<GetSettingResponse["data"]>("settings", handleRequest);
}
